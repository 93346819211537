import React, { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'component/Loadable';
import { AuthGuard } from 'hooks/Auth/useAuthGuard';

const DashboardDefault = Loadable(lazy(() => import('../views/Dashboard')));

const UtilsTypography = Loadable(lazy(() => import('../views/Utils/Typography')));

const SamplePage = Loadable(lazy(() => import('../views/SamplePage')));
const StarterPage = Loadable(lazy(() => import('../views/StarterPage')));
const TestingPage = Loadable(lazy(() => import('../views/TestingPage')));
const UserPage = Loadable(lazy(() => import('../views/User')));
// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  element: <AuthGuard>
      <MainLayout />
    </AuthGuard>,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/dashboard/default',
      element: <DashboardDefault />
    },
    { path: '/utils/util-typography', element: <UtilsTypography /> },
    { path: '/starter', element: <StarterPage /> },
    { path: '/test', element: <TestingPage /> },
    { path: '/user', element: <UserPage /> },
    { path: '*', element: <>404 Page</> }
  ]
};

export default MainRoutes;
