// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#48415d`,
	"primaryDark": `#0043a9`,
	"primaryLight": `#3380f4`,
	"primary100": `#4c6fff`,
	"secondary": `#425466`,
	"secondaryDark": `#272f33`,
	"error": `#ff413a`,
	"errorDark": `#a20e00`,
	"errorLight": `#ec4333`,
	"warning": `#f4a100`,
	"warningDark": `#aa7000`,
	"warningLight": `#f6b333`,
	"info": `#00cfd5`,
	"infoDark": `#009095`,
	"infoLight": `#33d8dd`,
	"success": `#00ac69`,
	"successDark": `#007849`,
	"successLight": `#33bc87`,
	"grey300": `#425466`,
	"bg100": `#f8f8f9`,
	"textPrimary": `#242c3a`,
	"textSecondary": `#1b212c`,
	"textDark": `#12171e`,
	"textHint": `rgba(0, 0, 0, 0.3803921569)`,
	"backgound": `#f0f2f8`,
	"paper": `#ffffff`,
	"paperDark": `#232b38`,
	"menuHover": `#e3f2fd`
};
export default ___CSS_LOADER_EXPORT___;
