import axios from 'axios';

const base = process.env.REACT_APP_API_URL;

const Testing = {
    Login: async (body) => axios.post(base+'/auth/signin',body),
}

const User = {//path = app/portal
    get_all: async () => axios.get(base+`/users`),
    get_one: async (id) => axios.get(base+`/users/${id}`),
    create_one: async (path,body) => axios.post(base+`/users`,body),
    update_one: async (path,userId,body) => axios.patch(base+`/users/${userId}`,body),
    delete_one: async (path,id) => axios.delete(base+`/users/${id}`),
}

export default {
    Testing,
    User
}