import { createContext, useEffect, useState } from 'react';
import axios from 'axios';

// Create the UserContext
const UserContext = createContext();

// Create a UserProvider component
const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const init = () => {
        const u = localStorage.getItem("u");
        if (u) {
            setUser(JSON.parse((window.atob(u))));
            axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        }
    }
    // Define a function to update the user information
    const updateUser = (userInfo) => {
        setUser(userInfo);
    };

    useEffect(() => {
        init()
    }, [])

    // Provide the user and updateUser function to the child components
    return (
        <UserContext.Provider value={{ user, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };