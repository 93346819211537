import { useContext } from 'react';
import { UserContext } from './user';
import { useNavigate } from 'react-router-dom';
import API from '../../api';
import axios from 'axios';

const JwtService = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useContext(UserContext);
  const login = async (loginInfo) => {
    return API.Testing.Login(loginInfo).then(( data )=>{
      let auth = data.data;
      //set the localstorage token
      localStorage.token = auth.access_token;
      let user = { id: auth.id, email: auth.email, name: auth.username };
      //set the user in localstorage
      localStorage.u = window.btoa(JSON.stringify(user));
      //set the user in state
      axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      updateUser(user);
      navigate('/');
      return true;
    }).catch(e=>{
      console.log(e)
      return false;
    });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('u');
    navigate('/login')
  }

  return {
    user,
    login,
    logout
  };
};

export { JwtService };