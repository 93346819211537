import { useEffect } from "react";
import { useNavigate } from "react-router";

export const AuthGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is authenticated
    const token = localStorage.getItem("token");
    if (!token) {
      // User is not authenticated, redirect to login page or any other desired route
      navigate("/login");
    }
  }, [navigate, children]);

  // Render the children components if the user is authenticated
  return children;
};