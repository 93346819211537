// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[0].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[0].oneOf[7].use[4]!../../../node_modules/react-perfect-scrollbar/dist/css/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* breadcrumb */
.link-breadcrumb {
  font-size: 0.75rem;
  display: flex;
  text-decoration: none;
}

/* card */
.card-header {
  font-weight: 600 !important;
  font-size: 15px;
}

.apexcharts-legend-marker {
  margin-right: 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/style.scss"],"names":[],"mappings":"AAGA,eAAA;AACA;EACE,kBAAA;EACA,aAAA;EACA,qBAAA;AA+BF;;AA5BA,SAAA;AAEA;EACE,2BAAA;EACA,eAAA;AA8BF;;AA3BA;EACE,4BAAA;AA8BF","sourcesContent":["@import 'themes-vars';\r\n@import 'plugins/plugins';\r\n\r\n/* breadcrumb */\r\n.link-breadcrumb {\r\n  font-size: 0.75rem;\r\n  display: flex;\r\n  text-decoration: none;\r\n}\r\n\r\n/* card */\r\n\r\n.card-header {\r\n  font-weight: 600 !important;\r\n  font-size: 15px;\r\n}\r\n\r\n.apexcharts-legend-marker {\r\n  margin-right: 8px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#48415d`,
	"primaryDark": `#0043a9`,
	"primaryLight": `#3380f4`,
	"primary100": `#4c6fff`,
	"secondary": `#425466`,
	"secondaryDark": `#272f33`,
	"error": `#ff413a`,
	"errorDark": `#a20e00`,
	"errorLight": `#ec4333`,
	"warning": `#f4a100`,
	"warningDark": `#aa7000`,
	"warningLight": `#f6b333`,
	"info": `#00cfd5`,
	"infoDark": `#009095`,
	"infoLight": `#33d8dd`,
	"success": `#00ac69`,
	"successDark": `#007849`,
	"successLight": `#33bc87`,
	"grey300": `#425466`,
	"bg100": `#f8f8f9`,
	"textPrimary": `#242c3a`,
	"textSecondary": `#1b212c`,
	"textDark": `#12171e`,
	"textHint": `rgba(0, 0, 0, 0.3803921569)`,
	"backgound": `#f0f2f8`,
	"paper": `#ffffff`,
	"paperDark": `#232b38`,
	"menuHover": `#e3f2fd`
};
export default ___CSS_LOADER_EXPORT___;
